var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import AppsTabsComponent from './Apps/AppsTabsComponent.vue';
import IconShop from '@/components/Icons/IconShop.vue';
import IconSingleCredit from '@/components/Icons/IconSingleCredit.vue';
import IconCard from '@/components/Icons/IconCard.vue';
import { mixpanelTracking } from '@/services/mixpanel';
let ShopTabsComponent = class ShopTabsComponent extends Vue {
    selectTab(selected) {
        this.tabs.forEach(tab => {
            tab.selected = false;
            if (selected == tab.id) {
                tab.selected = true;
                mixpanelTracking(`shop: Abriu a aba ${tab.name}`);
            }
        });
    }
};
__decorate([
    Prop({ default: () => { } })
], ShopTabsComponent.prototype, "currentUser", void 0);
__decorate([
    Prop({ default: () => { } })
], ShopTabsComponent.prototype, "tabs", void 0);
ShopTabsComponent = __decorate([
    Component({
        name: 'ShopTabsComponent',
        components: {
            AppsTabsComponent,
            IconShop,
            IconSingleCredit,
            IconCard,
        },
    })
], ShopTabsComponent);
export default ShopTabsComponent;
