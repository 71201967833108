var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import ShopComponent from '../components/ShopComponent.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { connect } from '@/overmind';
import { isEmpty } from 'lodash';
let ShopContainer = class ShopContainer extends Vue {
    constructor() {
        super(...arguments);
        this.user = {};
        this.title = '';
        this.subtitle = '';
        this.tabs = [
            /*{
              id: 1,
              to: '/shop/overview',
              text: 'Loja de Aplicativos',
              icon: 'IconShop',
              name: 'Loja de Aplicativos',
              subtitle: 'Tudo para você destravar suas vendas',
              selected: true,
            },
            {
              id: 2,
              to: '/shop/single-credit',
              text: 'Comprar crédito avulso',
              icon: 'IconSingleCredit',
              name: 'Comprar crédito avulso',
              selected: false,
            },*/
            {
                id: 3,
                to: '/shop/manage-subscriptions',
                text: 'Gerenciar minhas cobranças',
                icon: 'IconCard',
                name: 'Gerenciar minhas cobranças',
                subtitle: 'Todos os apps, filtros e recursos ativos em sua assinatura no momento',
                selected: false,
            },
        ];
        this.pages = {
            overview: {
                title: this.tabs[0].text,
                subtitle: this.tabs[0].subtitle,
            },
            'single-credit': {
                title: 'Comprar Créditos avulsos',
                subtitle: 'Teste',
            },
            'manage-subscriptions': {
                title: this.tabs[1] ? this.tabs[1].text : undefined,
                subtitle: this.tabs[1] ? this.tabs[1].subtitle : undefined,
            },
        };
    }
    verifyAuth() {
        // @ts-ignore TS2349: property inexistent
        if (this.state.statusResponse.code === 401) {
            // @ts-ignore TS2349: property inexistent
            this.$auth.logout();
        }
    }
    async mounted() {
        // @ts-ignore TS2349: property inexistent
        if (isEmpty(this.state.currentUser)) {
            // @ts-ignore TS2349: property inexistent
            this.user = this.$auth.user();
        }
        this.verifyAuth();
        // @ts-ignore TS2349: property inexistent
        await this.actions.getSubUsers();
        // @ts-ignore TS2349: property inexistent
        await this.actions.getUserInformation();
        // @ts-ignore TS2349: property inexistent
        await this.actions.getUserMe();
    }
    onRouteChange(val) {
        this.title = '';
        this.subtitle = '';
        if (this.pages[val.name]) {
            this.title = this.pages[val.name].title;
            this.subtitle = this.pages[val.name].subtitle;
            return;
        }
    }
};
__decorate([
    Watch('$route', { immediate: true, deep: true })
], ShopContainer.prototype, "onRouteChange", null);
ShopContainer = __decorate([
    Component(connect(({ state, actions }) => ({
        state: state.users,
        actions: actions.users,
        infosetsActions: actions.infosets,
    }), {
        name: 'ShopContainer',
        components: {
            ShopComponent,
        },
    }))
], ShopContainer);
export default ShopContainer;
