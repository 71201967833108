var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import ShopContainer from '@/modules/shop/containers/Shop.container.vue';
import { mixpanelTracking } from '@/services/mixpanel';
import ContactAndSalesInfoModal from '@/components/ContactAndSalesInfoModal/ContactAndSalesInfoModal.vue';
import ReactivationAndReengagementModal from '@/components/ReactivationAndReengagementModal/ReactivationAndReengagementModal.vue';
let Shop = class Shop extends Vue {
    mounted() {
        mixpanelTracking('shop: Acessou página da loja');
    }
};
Shop = __decorate([
    Component({
        name: 'Shop',
        components: {
            ShopContainer,
            ContactAndSalesInfoModal,
            ReactivationAndReengagementModal,
        },
    })
], Shop);
export default Shop;
