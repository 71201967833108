var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import { mixpanelTracking } from '@/services/mixpanel';
let AppsTabsComponent = class AppsTabsComponent extends Vue {
    constructor() {
        super(...arguments);
        this.showAnnotationConfig = false;
        this.showRecordConfig = false;
        this.showMailerWeb = false;
        this.exportOracle = false;
        this.showSpeedMail = false;
        this.showWhatsAppAutomationApp = false;
    }
    mounted() {
        if (this.$route.name === 'anotationApp') {
            this.showAnnotationConfig = true;
        }
        else if (this.$route.name === 'recordApp') {
            this.showRecordConfig = true;
        }
        else if (this.$route.name === 'exportOracle') {
            this.exportOracle = true;
        }
        else if (this.$route.name === 'mailerWebApp') {
            this.showMailerWeb = true;
        }
        else if (this.$route.name === 'speedMailApp') {
            this.showSpeedMail = true;
        }
        else if (this.$route.name === 'whatsappAutomationApp') {
            this.showWhatsAppAutomationApp = true;
        }
    }
    insideSpeedmailApp() {
        return this.$route.path.match(/speedmail-app/);
    }
    mailerWebIsActive() {
        const subdomains = this.currentUser.email &&
            this.currentUser.email.match(new RegExp(/(speedio|speediomail)\b/, 'gi'));
        const haveAlphaEmail = [
            'clarissapvaz@gmail.com',
            'raphael@leadenergy.com.br',
            'eduardo.boff@qualitor.com.br',
        ].includes(this.currentUser.email);
        return (subdomains && subdomains.length > 0) || haveAlphaEmail;
    }
    whatsappIsActive() {
        return (window.location.hostname.match('staging') ||
            window.location.hostname.match('test') ||
            window.location.hostname.match('dev') ||
            window.location.hostname.match('localhost'));
    }
    getUserSpecialConditions() {
        if (this.currentUser.email &&
            this.currentUser.email.match(new RegExp(/(speedio|speediomail|oracle|movetruly)\b/, 'gi'))) {
            return true;
        }
        else {
            return false;
        }
    }
    handleMixpanelTabTraking(text) {
        mixpanelTracking(`shop: Abriu a aba ${text}`);
    }
    showSpeedmailApp() {
        // @ts-ignore
        return this.$auth.user().showSpeedmailApp;
    }
};
__decorate([
    Prop({ default: () => { } })
], AppsTabsComponent.prototype, "currentUser", void 0);
AppsTabsComponent = __decorate([
    Component({
        name: 'AppsTabsComponent',
    })
], AppsTabsComponent);
export default AppsTabsComponent;
