import { render, staticRenderFns } from "./ShopContentComponent.vue?vue&type=template&id=5e409f75&scoped=true&"
import script from "./ShopContentComponent.vue?vue&type=script&lang=ts&"
export * from "./ShopContentComponent.vue?vue&type=script&lang=ts&"
import style0 from "./ShopContentComponent.vue?vue&type=style&index=0&id=5e409f75&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e409f75",
  null
  
)

export default component.exports